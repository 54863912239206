import _export from "../internals/export";
import _flattenIntoArray from "../internals/flatten-into-array";
import _toObject from "../internals/to-object";
import _lengthOfArrayLike from "../internals/length-of-array-like";
import _toIntegerOrInfinity from "../internals/to-integer-or-infinity";
import _arraySpeciesCreate from "../internals/array-species-create";
var $ = _export;
var flattenIntoArray = _flattenIntoArray;
var toObject = _toObject;
var lengthOfArrayLike = _lengthOfArrayLike;
var toIntegerOrInfinity = _toIntegerOrInfinity;
var arraySpeciesCreate = _arraySpeciesCreate;

// `Array.prototype.flat` method
// https://tc39.es/ecma262/#sec-array.prototype.flat
$({
  target: "Array",
  proto: true
}, {
  flat: function flat( /* depthArg = 1 */
  ) {
    var depthArg = arguments.length ? arguments[0] : undefined;
    var O = toObject(this);
    var sourceLen = lengthOfArrayLike(O);
    var A = arraySpeciesCreate(O, 0);
    A.length = flattenIntoArray(A, O, O, sourceLen, 0, depthArg === undefined ? 1 : toIntegerOrInfinity(depthArg));
    return A;
  }
});
export default {};